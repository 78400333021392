import olympus35rc_tmax_beale from "../assets/photography/35rctmaxbeale.jpg";
import mat124g_hp5_xtown_farmburger from "../assets/photography/124ghp5xtown1.jpg";
import mat124g_hp5_xtown_skylight from "../assets/photography/124ghp5xtown2.jpg";
import h500cm_ektar_zeke from "../assets/photography/500cmektarzeke.jpg";
import h500cm_hp5_channingvalley from "../assets/photography/500cmhp5channingvalley.jpg";
import h500cm_portra400_ktm from "../assets/photography/500cmportra400ktm.jpg";
import h500cm_portra400_woodstock from "../assets/photography/500cmportra400woodstock.jpg";
import h500cm_trix_woodstock from "../assets/photography/500cmtrixwoodstock.jpg";
import av1_panf_rhodes from "../assets/photography/av1panfrhodes.jpg";
import av1_vista_rome from "../assets/photography/av1vistarome.jpg";
import av1_vista_sanvigilio from "../assets/photography/av1vistasanvigilio.jpg";
import av1_vista_venice from "../assets/photography/av1vistavenice.jpg";
import cl_colorplus_lullwater from "../assets/photography/clcolorpluslullwater.jpg";
import cl_ektachrome_pool from "../assets/photography/clektagablespool.jpg";
import cl_fomapan_masonmill from "../assets/photography/clfomapanmasonmill.jpg";
import cl_tmax_atlhistcenter from "../assets/photography/cltmaxatlhistcenter.jpg";
import cl_trix_bhamcarsandcoffee from "../assets/photography/cltrixcarscoffee.jpg";
import f4_provia_midtown from "../assets/photography/f4proviamidtown.jpg";
import f4_250d_typbee from "../assets/photography/f4250dtybee.jpg";
import fe2_hp5_sweetwater from "../assets/photography/fe2hp5sweetwater.jpg";
import fe2_trix_atlutd from "../assets/photography/fe2trixmercstadium.jpg";
import fe2_trix_covid from "../assets/photography/fe2trixcovid.jpg";
import m645s_delta3200_rites from "../assets/photography/m645sdelta3200rites.jpg";
import m645s_portra160_stlzoo_butterfly from "../assets/photography/m645sp160stlzoo1.jpg";
import m645s_portra160_stlzoo_sculpture from "../assets/photography/m645sp160stlzoo2.jpg";
import xa_c200_dc from "../assets/photography/xac200dc.jpg";
import xa_c200_dcsunset from "../assets/photography/xac200dcsunset.jpg";
import xa_provia_austin from "../assets/photography/xaproviaaustin.jpg";
import xe1_otis from "../assets/photography/xe1otis.jpg";
import zorki_vista_oslo from "../assets/photography/zorkivistaoslo.jpg";

const photos = [
    {
        src: xa_c200_dc,
        description: "Olympus XA | Fuji C200",
        location: "Washington, D.C.",
        date: "August 2022"
    },
    {
        src: h500cm_ektar_zeke,
        description: "Hasselblad 500C/M | 80mm f/2.8 C | Kodak Ektar 100",
        location: "Vestavia Hills, AL",
        date: "September 2020"
    },
    {
        src: fe2_trix_atlutd,
        description: "Nikon FE2 | 50mm f/1.8 Series E | Kodak Tri-X 400",
        location: "Atlanta, GA",
        date: "September 2019"
    },
    {
        src: xa_c200_dcsunset,
        description: "Olympus XA | Fuji C200",
        location: "Washington, D.C.",
        date: "August 2022"
    },
    {
        src: f4_provia_midtown,
        description: "Nikon F4 | 35-70mm f/2.8 AF | Fuji Provia",
        location: "Atlanta, GA",
        date: "June 2021"
    },
    {
        src: cl_colorplus_lullwater,
        description: "Leitz-Minolta CL | Nokton Classic 40mm f/1.4 | Kodak ColorPlus 200",
        location: "Toco Hills, GA",
        date: "April 2019"
    },
    {
        src: h500cm_portra400_woodstock,
        description: "Hasselblad 500C/M | 80mm f/2.8 C | Kodak Portra 400",
        location: "Woodstock, GA",
        date: "October 2020"
    },
    {
        src: av1_vista_rome,
        description: "Canon AV-1 | 50mm f/1.8 FD | Agfa Vista 200",
        location: "Rome, Italy",
        date: "October 2016"
    },
    {
        src: xe1_otis,
        description: "Fujifilm X-E1 | Sigma 30mm f/1.4",
        location: "North Decatur, GA",
        date: "June 2023"
    },
    {
        src: mat124g_hp5_xtown_farmburger,
        description: "Yashica Mat-124G | Ilford HP5",
        location: "Memphis, TN",
        date: "August 2017"
    },
    {
        src: xa_provia_austin,
        description: "Olympus XA | Fuji Provia",
        location: "Austin, TX",
        date: "May 2022"
    },
    {
        src: h500cm_trix_woodstock,
        description: "Hasselblad 500C/M | 80mm f/2.8 C | Kodak Tri-X 400",
        location: "Woodstock, GA",
        date: "May 2021"
    },
    {
        src: olympus35rc_tmax_beale,
        description: "Olympus 35RC | Kodak TMax 400",
        location: "Memphis, TN",
        date: "February 2018"
    },
    {
        src: m645s_portra160_stlzoo_butterfly,
        description: "Mamiya M645 Super | 80mm f/2.8N | Kodak Portra 160",
        location: "St. Louis, MO",
        date: "March 2018"
    },
    {
        src: cl_trix_bhamcarsandcoffee,
        description: "Leitz-Minolta CL | Nokton Classic 40mm f/1.4 | Kodak Tri-X 400",
        location: "Birmingham, AL",
        date: "June 2019"
    },
    {
        src: h500cm_portra400_ktm,
        description: "Hasselblad 500C/M | 80mm f/2.8 C | Kodak Portra 400",
        location: "Atlanta, GA",
        date: "November 2021"
    },
    {
        src: fe2_hp5_sweetwater,
        description: "Nikon FE2 | 50mm f/1.8 Series E | Ilford HP5",
        location: "Sweetwater Creek State Park, GA",
        date: "December 2019"
    },
    {
        src: av1_vista_sanvigilio,
        description: "Canon AV-1 | 50mm f/1.8 FD | Agfa Vista 200",
        location: "San Vigilio, Italy",
        date: "October 2016"
    },
    {
        src: m645s_delta3200_rites,
        description: "Mamiya M645 Super | 80mm f/2.8N | Kodak P3200",
        location: "Memphis, TN",
        date: "May 2018"
    },
    {
        src: mat124g_hp5_xtown_skylight,
        description: "Yashica Mat-124G | Ilford HP5",
        location: "Memphis, TN",
        date: "August 2017"
    },
    {
        src: cl_ektachrome_pool,
        description: "Leitz-Minolta CL | Nokton Classic 40mm f/1.4 | Kodak Ektachrome 100",
        location: "Atlanta, GA",
        date: "August 2019"
    },
    {
        src: av1_panf_rhodes,
        description: "Canon AV-1 | 50mm f/1.8 FD | Ilford PanF",
        location: "Memphis, TN",
        date: "January 2017"
    },
    {
        src: m645s_portra160_stlzoo_sculpture,
        description: "Mamiya M645 Super | 80mm f/2.8N | Kodak Portra 160",
        location: "St. Louis, MO",
        date: "March 2018"
    },
    {
        src: f4_250d_typbee,
        description: "Nikon F4 | 35-70mm f/2.8 AF | Kodak Vision3 250D",
        location: "Tybee Island, GA",
        date: "October 2021"
    },
    {
        src: cl_fomapan_masonmill,
        description: "Leitz-Minolta CL | Nokton Classic  40mm f/1.4 | Fomapan 400",
        location: "Decatur, GA",
        date: "April 2019"
    },
    {
        src: zorki_vista_oslo,
        description: "Zorki-4k | Jupiter-8 | Agfa Vista 200",
        location: "Oslo, Norway",
        date: "September 2016"
    },
    {
        src: av1_vista_venice,
        description: "Canon AV-1 | 50mm f/1.8 FD | Agfa Vista 200",
        location: "Venice, Italy",
        date: "October 2016"
    },
    {
        src: h500cm_hp5_channingvalley,
        description: "Hasselblad 500C/M | 80mm f/2.8 C | Ilford HP5",
        location: "Buckhead, GA",
        date: "April 2020"
    },
    {
        src: cl_tmax_atlhistcenter,
        description: "Leitz-Minolta CL | Nokton Classic  40mm f/1.4 | Kodak TMax 400",
        location: "Decatur, GA",
        date: "April 2019"
    },
    {
        src: fe2_trix_covid,
        description: "Nikon FE2 | 50mm f/1.8 Series E | Kodak Tri-X 400",
        location: "Woodstock, GA",
        date: "October 2020"
    },
]

export default photos;