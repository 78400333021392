// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#subTitle {
  color: #000000;
  font-size: 5vw;
  text-align: left;
  margin-right: 1px;
  margin-top: 0px;
  line-height: 145%;
  font-weight: 700;
  margin-bottom: 50px;
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 70px;
}

/* ANIMATION: */
.animateSubTitle {
  animation: animateSubTitle 1.2s cubic-bezier(0.2, 0.6, 0.2, 1);
  animation-fill-mode: backwards;
}

.shortDelay {
  animation-delay: 0.2s;
}

@keyframes animateSubTitle {
  0% {
    transform: translateX(-115%);
  }
  100% {
    transform: translateX(0%);
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/subHeader.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA,eAAA;AAEA;EACE,8DAAA;EACA,8BAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE;IACE,4BAAA;EAAF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":["#subTitle {\n  color: #000000;\n  font-size: 5vw;\n  text-align: left;\n  margin-right: 1px;\n  margin-top: 0px;\n  line-height: 145%;\n  font-weight: 700;\n  margin-bottom: 50px;\n  display: block;\n  position: relative;\n  overflow: hidden;\n  margin-bottom: 70px;\n}\n\n/* ANIMATION: */\n\n.animateSubTitle {\n  animation: animateSubTitle 1.2s cubic-bezier(0.2, 0.6, 0.2, 1);\n  animation-fill-mode: backwards;\n}\n\n.shortDelay {\n  animation-delay: 0.2s;\n}\n\n@keyframes animateSubTitle {\n  0% {\n    transform: translateX(-115%);\n  }\n  100% {\n    transform: translateX(0%);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
