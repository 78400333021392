// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0px;
  font-family: "Open Sans", Arial, sans-serif;
  color: #000000;
}

::selection {
  background: #a7a7a7; /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: #a7a7a7; /* Gecko Browsers */
}

/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,2CAAA;EACA,cAAA;AACJ;;AAEA;EACE,mBAAA,EAAA,0BAAA;AACF;;AACA;EACE,mBAAA,EAAA,mBAAA;AAEF;;AACA,cAAA;AAEA,UAAA;AACA;EACI,WAAA;AACJ;;AAEE,UAAA;AACA;EACE,iBAAA;EACA,mBAAA;AACJ;;AAEE,WAAA;AACA;EACE,iBAAA;EACA,mBAAA;AACJ;;AAEE,oBAAA;AACA;EACE,2BAAA;AACJ","sourcesContent":["body {\n    margin: 0px;\n    font-family: 'Open Sans', Arial, sans-serif;\n    color: #000000\n}\n\n::selection {\n  background: #a7a7a7; /* WebKit/Blink Browsers */\n}\n::-moz-selection {\n  background: #a7a7a7; /* Gecko Browsers */\n}\n\n/* SCROLLBAR */\n\n/* width */\n::-webkit-scrollbar {\n    width: 12px;\n  }\n  \n  /* Track */\n  ::-webkit-scrollbar-track {\n    background: white; \n    border-radius: 10px;\n  }\n  \n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background: black; \n    border-radius: 10px;\n  }\n  \n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    background: rgb(92, 92, 92); \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
