import React, { useState } from "react";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import HomeBtn from "../components/homeBtn";
import SubHeader from "../components/subHeader";
import photo from "../models/photo";

import "../styles/photography.scss";

import images from "../tools/getphotos";

function Photography() {
  const [image, setImage] = useState(images[0]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const setActiveImage = (photo: photo) => {
    setImage(photo);
    handleShow();
  }

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        size={"xl"}
      >
        <Modal.Body>
          <Image src={image.src} alt={image.src} className="photo" />
          <div id="modal-label">
            <p>{image.description}</p>
            <p>{image.location}</p>
            <p>{image.date}</p>
          </div>
        </Modal.Body>
      </Modal>
      <HomeBtn />
      <Container>
        <Row>
          <Col>
            <SubHeader title={"Photography"} />
            {images.map((image, index) => {
              console.log(image)
              return <Button className="modal-thumb" key={index} onClick={() => setActiveImage(image)}><Image src={image.src} alt={image.src} /></Button>
            })}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const container = document.createElement("div");
document.body.appendChild(container);
export default Photography;
