import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './pages/homepage';
import Photography from './pages/photography';
import ContactInfo from "./components/contactInfo";
import "./styles/app.scss";

const App = () => (
  <React.Fragment>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/photography' element={<Photography />} />
      </Routes>
    </BrowserRouter>
    <ContactInfo />
  </React.Fragment>
);

export default App;
