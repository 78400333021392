// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#homeBtn {
  width: 100px;
  height: 50px;
  background-color: white;
  border: 1px solid black;
  color: black;
  display: block;
  cursor: pointer;
  font-size: 1em;
  text-align: center;
  line-height: 50px;
  text-decoration: none;
  margin: 10px auto 10px 10px;
  float: left;
}
#homeBtn:active {
  position: relative;
  text-decoration: none;
  top: 3px;
}
#homeBtn:hover {
  background-color: black;
  text-decoration: none;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/homeBtn.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,uBAAA;EACA,uBAAA;EACA,YAAA;EACH,cAAA;EACA,eAAA;EACG,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;EACA,2BAAA;EACA,WAAA;AACJ;AAAI;EACI,kBAAA;EACA,qBAAA;EACA,QAAA;AAER;AAAI;EACI,uBAAA;EACA,qBAAA;EACA,YAAA;AAER","sourcesContent":["#homeBtn {\n    width: 100px;\n    height: 50px;\n    background-color:white;\n    border: 1px solid black;\n    color : black;\n\tdisplay: block;\n\tcursor:pointer;\n    font-size: 1em;\n    text-align: center;\n    line-height: 50px;\n    text-decoration: none;\n    margin: 10px auto 10px 10px;\n    float: left;\n    &:active {\n        position:relative;\n        text-decoration: none;\n        top: 3px;\n    }\n    &:hover {\n        background-color: black;\n        text-decoration: none;\n        color: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
