// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-thumb {
  background-color: #ffffff;
  border: none;
  border-radius: 0px;
  width: 200px;
  height: 200px;
  margin: 0px;
  padding: 0px;
  transition: all 1s ease;
}
.modal-thumb * {
  border-radius: 0px;
}
.modal-thumb:active {
  background-color: #d9d9d9 !important;
}
.modal-thumb:focus {
  background-color: #f2f2f2 !important;
  border: none;
  box-shadow: none;
}
.modal-thumb:hover {
  background-color: #ffffff;
  filter: brightness(85%);
  transition: all 0.5s ease;
}
.modal-thumb img {
  max-height: 93% !important;
  max-width: 93%;
}

.modal {
  border: none;
}
.modal * {
  border: none;
}
.modal .modal-dialog {
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.modal .modal-dialog .modal-content {
  border-radius: 0px;
}
.modal .modal-dialog .modal-content .modal-body img {
  max-width: 100%;
  max-height: 85vh;
}
.modal .modal-dialog .modal-content .modal-body #modal-label {
  padding-top: 1rem;
}
.modal .modal-dialog .modal-content .modal-body #modal-label p {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/styles/photography.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;AACJ;AAAI;EACI,kBAAA;AAER;AAAI;EACI,oCAAA;AAER;AAAI;EACI,oCAAA;EACA,YAAA;EACA,gBAAA;AAER;AAAI;EACI,yBAAA;EACA,uBAAA;EACA,yBAAA;AAER;AACI;EACI,0BAAA;EACA,cAAA;AACR;;AAGA;EACI,YAAA;AAAJ;AACI;EACI,YAAA;AACR;AACI;EACI,wBAAA;EAAA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AACR;AAAQ;EACI,kBAAA;AAEZ;AAAgB;EACI,eAAA;EACA,gBAAA;AAEpB;AAAgB;EACI,iBAAA;AAEpB;AADoB;EACI,iBAAA;AAGxB","sourcesContent":[".modal-thumb {\n    background-color: #ffffff;\n    border: none;\n    border-radius: 0px;\n    width: 200px;\n    height: 200px;\n    margin: 0px;\n    padding: 0px;\n    transition: all 1s ease;\n    * {\n        border-radius: 0px;\n    }\n    &:active {\n        background-color: darken(#ffffff, 15%)  !important;\n    }\n    &:focus {\n        background-color: darken(#ffffff, 5%)  !important;\n        border: none;\n        box-shadow: none;\n    }\n    &:hover {\n        background-color: #ffffff;\n        filter: brightness(85%);\n        transition: all .5s ease;\n    }\n\n    img {\n        max-height: 93% !important;\n        max-width: 93%\n    }\n}\n\n.modal {\n    border: none;\n    * {\n        border: none;\n    }\n    .modal-dialog {\n        height: fit-content;\n        width: fit-content;\n        .modal-content {\n            border-radius: 0px;\n            .modal-body {\n                img {\n                    max-width: 100%;\n                    max-height: calc(85vh);\n                }\n                #modal-label {\n                    padding-top: 1rem;\n                    p {\n                        text-align: right;\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
