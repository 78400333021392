// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#largeName {
  color: #000000;
  font-size: 17vw;
  text-align: right;
  margin-right: 1px;
  margin-top: 0px;
  line-height: 127%;
  font-weight: 700;
  margin-bottom: 300px;
}

.largeText {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
}

/* ANIMATION: */
.animate {
  animation: animate 1.2s cubic-bezier(0.2, 0.6, 0.2, 1);
  animation-fill-mode: backwards;
}

.animate-inverse {
  animation: animate-inverse 1.2s cubic-bezier(0.2, 0.6, 0.2, 1);
  animation-fill-mode: backwards;
}

.shortDelay {
  animation-delay: 0.2s;
}

.longDelay {
  animation-delay: 0.5s;
}

@keyframes animate {
  0% {
    transform: translateY(115%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes animate-inverse {
  0% {
    transform: translateY(-110%);
  }
  100% {
    transform: translateY(0%);
  }
}
@media only screen and (max-width: 600px) {
  #largeName {
    font-size: 4.5em;
    margin-bottom: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/LgName.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAEA,eAAA;AAEA;EACI,sDAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,8DAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,qBAAA;AAAJ;;AAGA;EACI,qBAAA;AAAJ;;AAGA;EACI;IACI,2BAAA;EAAN;EAEE;IACI,yBAAA;EAAN;AACF;AAGA;EACI;IACI,4BAAA;EADN;EAGE;IACI,yBAAA;EADN;AACF;AAIA;EACI;IACI,gBAAA;IACA,mBAAA;EAFN;AACF","sourcesContent":["#largeName {\n    color: #000000;\n    font-size: 17vw;\n    text-align: right;\n    margin-right: 1px;\n    margin-top: 0px;\n    line-height: 127%;\n    font-weight: 700;\n    margin-bottom: 300px;\n}\n\n.largeText {\n    display: block;\n    position: relative;\n    overflow: hidden;\n    margin-bottom: 0px;\n}\n\n/* ANIMATION: */\n\n.animate {\n    animation: animate 1.2s cubic-bezier(.2, .6, .2, 1);\n    animation-fill-mode: backwards;\n}\n\n.animate-inverse {\n    animation: animate-inverse 1.2s cubic-bezier(.2, .6, .2, 1);\n    animation-fill-mode: backwards;\n}\n\n.shortDelay {\n    animation-delay: 0.2s;\n}\n\n.longDelay {\n    animation-delay: 0.5s;\n}\n\n@keyframes animate {\n    0% {\n        transform: translateY(115%);\n    }\n    100% {\n        transform: translateY(0%);\n    }\n}\n\n@keyframes animate-inverse {\n    0% {\n        transform: translateY(-110%);\n    }\n    100% {\n        transform: translateY(0%);\n    }\n}\n\n@media only screen and (max-width: 600px) {\n    #largeName {\n        font-size: 4.5em;\n        margin-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
