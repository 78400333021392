// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#contactInfo {
  width: 99%;
  margin-right: auto;
  overflow: hidden;
}
#contactInfo .contact {
  clear: both;
  font-size: 5.5vw;
  font-weight: 700;
  line-height: 75%;
  margin-block-end: 0px;
  margin-block-start: 0px;
  margin-top: 100px;
}
#contactInfo .linkImg {
  margin: 0px 0px 1rem 0.5rem;
  float: left;
  width: 5rem;
}
#contactInfo #email {
  margin: 0px 0px 0px 1%;
  font-size: 2em;
  clear: both;
}

@media only screen and (max-width: 600px) {
  .contact {
    margin-top: 3rem;
    font-size: 3em;
  }
  .contact #email {
    font-size: 1.5em;
  }
  .contact .linkImg {
    margin: 0% 0% 0.5rem 0.25rem;
    width: 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/contactInfo.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AACI;EACI,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;EACA,iBAAA;AACR;AAEI;EACI,2BAAA;EACA,WAAA;EACA,WAAA;AAAR;AAGI;EACI,sBAAA;EACA,cAAA;EACA,WAAA;AADR;;AAKA;EACI;IACI,gBAAA;IACA,cAAA;EAFN;EAGM;IACI,gBAAA;EADV;EAGM;IACI,4BAAA;IACA,WAAA;EADV;AACF","sourcesContent":["#contactInfo {\n    width: 99%;\n    margin-right: auto;\n    overflow: hidden;\n\n    .contact {\n        clear: both;\n        font-size: 5.5vw;\n        font-weight: 700;\n        line-height: 75%;\n        margin-block-end: 0px;\n        margin-block-start: 0px;\n        margin-top: 100px;\n    }\n\n    .linkImg {\n        margin: 0px 0px 1rem .5rem;\n        float: left;\n        width: 5rem;\n    }\n\n    #email {\n        margin: 0px 0px 0px 1%;\n        font-size: 2em;\n        clear: both;\n    }\n}\n\n@media only screen and (max-width: 600px) {\n    .contact {\n        margin-top: 3rem;\n        font-size: 3em;\n        #email {\n            font-size: 1.5em;\n        }\n        .linkImg {\n            margin: 0% 0% .5rem .25rem;\n            width: 4rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
