import React from "react";
import '../styles/LinkBtns.scss';

const LinkBtns = () => (
    <div>
        {/* <a className='linkBtn' href='./coding'>Coding Projects</a> */}
        <a className='linkBtn' href='./photography'>Photography</a>
    </div>
);

export default LinkBtns;