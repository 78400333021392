// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-weight: 100;
  margin-block-start: 0;
  margin-block-end: 0;
}

h2 {
  margin-block-start: 0;
  margin-block-end: 0;
}

#homepage {
  width: 960px;
  margin: 300px auto 0px auto;
}
#homepage #greeting {
  font-weight: 700;
  font-size: 2em;
  text-align: right;
}
#homepage #intro {
  float: left;
  width: 100%;
  font-size: 1.96em;
  margin-bottom: 25px;
  text-align: right;
}
#homepage #portrait {
  width: 90%;
}
#homepage .row:last-of-type {
  margin-top: 80px;
}
#homepage .row:last-of-type p {
  clear: both;
  font-size: 1.5em;
  margin-bottom: 20px;
  font-weight: 300;
}
#homepage .row:last-of-type #resumeLink {
  font-size: 1.75em;
  color: black;
  text-decoration: underline;
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
  #greeting {
    display: none;
  }
  #intro {
    font-size: 1.25em;
  }
  #row2 {
    margin-top: 1rem;
  }
  .about {
    font-size: 1em;
  }
  #resumeLink {
    font-size: 1.5em;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/homepage.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,qBAAA;EACA,mBAAA;AACF;;AAEA;EACE,qBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,2BAAA;AACF;AAAE;EACE,gBAAA;EACA,cAAA;EACA,iBAAA;AAEJ;AAAE;EACE,WAAA;EACA,WAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,UAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AADI;EACE,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AAGN;AADI;EACE,iBAAA;EACA,YAAA;EACA,0BAAA;EACA,gBAAA;AAGN;;AAEA;EACE;IACE,WAAA;EACF;EACA;IACE,aAAA;EACF;EACA;IACE,iBAAA;EACF;EACA;IACE,gBAAA;EACF;EACA;IACE,cAAA;EACF;EACA;IACE,gBAAA;EACF;AACF","sourcesContent":["p {\n  font-weight: 100;\n  margin-block-start: 0;\n  margin-block-end: 0;\n}\n\nh2 {\n  margin-block-start: 0;\n  margin-block-end: 0;\n}\n\n#homepage {\n  width: 960px;\n  margin: 300px auto 0px auto;\n  #greeting {\n    font-weight: 700;\n    font-size: 2em;\n    text-align: right;\n  }\n  #intro {\n    float: left;\n    width: 100%;\n    font-size: 1.96em;\n    margin-bottom: 25px;\n    text-align: right;\n  }\n  #portrait {\n    width: 90%;\n  }\n  .row:last-of-type {\n    margin-top: 80px;\n    p {\n      clear: both;\n      font-size: 1.5em;\n      margin-bottom: 20px;\n      font-weight: 300;\n    }\n    #resumeLink {\n      font-size: 1.75em;\n      color: black;\n      text-decoration: underline;\n      font-weight: 700\n    }\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .container {\n    width: 100%;\n  }\n  #greeting {\n    display: none;\n  }\n  #intro {\n    font-size: 1.25em;\n  }\n  #row2 {\n    margin-top: 1rem;\n  }\n  .about {\n    font-size: 1em;\n  }\n  #resumeLink {\n    font-size: 1.5em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
